import React from 'react'
import { Helmet } from "react-helmet"
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import PageHero from '../components/PageHero'

export const NotFoundPageTemplate = ({
  heroImage
}) => (
  <>
    <PageHero Title="404" Image={heroImage} /> 
    <div className="pageContent pageContent__noAnimate notFound">
      <div className="pageContent__inner"> 
        <article className="theme__box theme__box--small theme__box--column">
          <div className="container">
            <h2><span role="img" aria-label="Thinking face emoji">🤔</span></h2>
            <p>We couldn't cook up a page for the URL you entered!</p>
            <p>Please check the URL, or use the menu to find the page you're after.</p>
            <Link className="btn" to="/">
              Back to home
            </Link>
          </div>   
        </article>  
      </div>
    </div>
  </>
 )

const NotFoundPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  return (
    <>    
      <Helmet>
        <meta charSet="utf-8" />
        <title>404</title>
        <meta name="description" content="This is the 404 page" />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <Layout>
        <NotFoundPageTemplate heroImage={frontmatter.heroImage} />
      </Layout>
    </>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query NotFoundPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "about-page" } }) {
      frontmatter {
        heroImage {
          childImageSharp {
            fluid(maxWidth: 1080, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        } 
      }
    }
  }
`
